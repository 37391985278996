
import { Component, Prop, Vue } from 'vue-property-decorator'

import { copyToClipboard } from '@/functions/events'

@Component({})
export default class DataIterator extends Vue {
    @Prop({ default: () => [] })
    labels!: Array<string>

    @Prop({ default: () => [] })
    data!: Array<string>

    copyToClipboard = copyToClipboard
}
